import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

import { Router } from "@angular/router";
import { API_LOGIN } from "src/app/core/constant";
import { psmsUserDetails } from "../_interfaces/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  private currentUserSubject: BehaviorSubject<psmsUserDetails>;
  public currentUser: Observable<psmsUserDetails>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<psmsUserDetails>(
      JSON.parse(localStorage.getItem("currentUser")!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(loginData: any) {
    const params = {
      username: loginData.email,
      password: loginData.password,
    }; 
    return this.http.post<any>(API_LOGIN, params).pipe(
      map((user: psmsUserDetails) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (user && user.access_token) {
          this.router.navigate(["/dashboard"]);
          this.isLoginSubject.next(true);
          this.currentUserSubject.next(user);
          localStorage.setItem("currentUser", JSON.stringify(user));
          return user;
        }
      })
    );
  }

  // GET CURRENT USER //

  public get currentUserValue(): psmsUserDetails {
    return this.currentUserSubject.value;
  }
  // IF WE HAVE TOKEN THE USER IS LOGGEDIN //
  private hasToken(): boolean {
    return !!localStorage.getItem("currentUser");
  }

  // CHECK LOGIN OR NOT //
  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
