<div class="card">
  <div id="samplepdf">
    <!-- <div class="table-responsive table-responsive-1 " style="width: 100%; margin: 20px 0;"> -->
    <div style="width: 100%; margin: 20px 0;">
      <table class="table">
        <tr>
          <td rowspan='2' colspan='1' style="width: 21%; text-align: center;"><img src="assets/images/p7.png"
              style="width: 100%;" /></td>
          <td rowspan='1' colspan='4' style="width: 73%; font-size: 19px;text-align: center;font-weight:bold">
            Dhariwal Infrastructure Limited</td>
          <td rowspan='2' colspan='1' style="width: 30%; font-size: 14px;text-align: center;font-weight:bold">
            DIL_IMS_HSE_F_004</td>
        </tr>
        <tr>
          <td rowspan='1' colspan='4' style="text-align: center;font-size: 19px; font-weight:bold">TOOL BOX TALK</td>
        </tr>
        <tr style="background-color: gray;">
          <td style="text-align: center;font-weight:bold">Issue No.</td>
          <td style="width: 17%; text-align: center;font-weight:bold">Revision No.</td>
          <td style="width: 19%;text-align: center;font-weight:bold">Last revised on</td>
          <td style="width: 19%;text-align: center;font-weight:bold">Last reviewed on</td>
          <td style="width: 15%;text-align: center;font-weight:bold">File No.</td>
          <td style="text-align: center;font-weight:bold">Page</td>
        </tr>
        <tr>
          <td style="height: 30px;text-align:center;">{{jsatbt.issueno}}</td>
          <td style="height: 30px;text-align:center;">{{jsatbt.revisionno}}</td>
          <td style="height: 30px;text-align:center;">{{jsatbt.lastrevisedon}}</td>
          <td style="height: 30px;text-align:center;">{{jsatbt.lastreviewedon}}</td>
          <td style="height: 30px;text-align:center;">{{jsatbt.fileno}}</td>
          <td style="height: 30px;text-align:center;">1 of 1</td>
        </tr>

      </table>
      <div style="width: 100%; margin: 20px 0; font-weight: 600;">
        <table class="table">
          <tr>
            <td colspan="3" style="padding: 3px; width: 50%;">Area/ Location : {{jsatbt.activityarea}}</td>
            <td colspan="3" style="padding: 3px; width: 50%;">Date : {{jsatbt.tbt_date}}</td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 3px;" rowspan="2">Department : {{jsatbt.department}}</td>
            <td colspan="3" style="padding: 3px;">PTW no (If any) : {{jsatbt.ptwno}}</td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 3px;">Working Agency (If any) : {{jsatbt.workingagency}}</td>
          </tr>
          <tr>
            <td colspan="6" style="padding: 3px;">
              <u>Topic Discussed: {{jsatbt.topicdiscussed}}</u> <br>
            </td>
          </tr>
          <tr>
            <td colspan="6" style="padding: 3px;">
              <u>Safety Instructions: </u> <br>
            </td>
          </tr>

          <tr>
            <td colspan="6" style="padding: 3px;">
              <br>
              <ul style="list-style-type: none; padding: 0;">
                <li *ngFor="let item of issueNo.jsaSafetydata; let i = index" style="text-decoration: none;">
                  {{i+1}} . {{item.safetyinstructions}}
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td style="padding: 3px; text-align: center;">Sr. No.</td>
            <td style="padding: 3px; text-align: center;">Employee No.</td>
            <td colspan="3" style="padding: 3px; text-align: center;">Name</td>
            <td style="padding: 3px; text-align: center;">Signature</td>
          </tr>
          <tr>
            <td style="padding: 3px; text-align: center;">1</td>
            <td style="padding: 3px; text-align: center;"></td>
            <td colspan="3" style="padding: 3px; text-align: center;"></td>
            <td style="padding: 3px; text-align: center;"></td>
          </tr>
          <tr>
            <td style="padding: 3px; text-align: center;">2</td>
            <td style="padding: 3px; text-align: center;"></td>
            <td colspan="3" style="padding: 3px; text-align: center;"></td>
            <td style="padding: 3px; text-align: center;"></td>
          </tr>
          <tr style="text-align: center;">
            <td style="padding: 3px;">3</td>
            <td style="padding: 3px;"></td>
            <td colspan="3" style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
          <tr style="text-align: center;">
            <td style="padding: 3px;">4</td>
            <td style="padding: 3px;"></td>
            <td colspan="3" style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
          <tr style="text-align: center;">
            <td style="padding: 3px;">5</td>
            <td style="padding: 3px;"></td>
            <td colspan="3" style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
          <tr style="text-align: center;">
            <td style="padding: 3px;">6</td>
            <td style="padding: 3px;"></td>
            <td colspan="3" style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
          <tr style="text-align: center;">
            <td style="padding: 3px;">7</td>
            <td style="padding: 3px;"></td>
            <td colspan="3" style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
          <tr style="text-align: center;">
            <td style="padding: 3px;">8</td>
            <td style="padding: 3px;"></td>
            <td colspan="3" style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
          <tr style="text-align: center;">
            <td style="padding: 3px;">9</td>
            <td style="padding: 3px;"></td>
            <td colspan="3" style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
          <tr>
            <td colspan="6" style="padding: 3px;"><strong><u>Tool Box Talk Given By :-</u></strong></td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 3px;">1) Name& Signature :</td>
            <td colspan="3" style="padding: 3px;">2) Name& Signature :</td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 3px;">Designation :</td>
            <td colspan="3" style="padding: 3px;">Designation :</td>
          </tr>
        </table>
        <!-- <table class="table ag-table-bordered">
          <thead>
            <tr>
              <th style=" text-align: center;">SL.No</th>
              <th style=" text-align: center;">Safety Instruction</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of issueNo.jsaSafetydata;let i = index">
              <td style=" text-align: center;">{{i+1}}</td>

              <td style=" text-align: center;">{{item.safetyinstructions}}</td>
            </tr>
          </tbody>
        </table> -->
      </div>

    </div>
  </div>
</div>