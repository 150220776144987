import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { API_CHANGE_PASSWORD, API_FORGOT_PASSWORD } from "../../constant";

@Injectable({
    providedIn: "root"
})
export class ChangePassword {
    constructor(
        private http: HttpClient
    ) { }
    forgotPassword(requestData: any): Observable<any> {
        return this.http.post<any>(API_FORGOT_PASSWORD, requestData).pipe(catchError(e => throwError(() => new Error)))
    }
    changepassword(requestData: any): Observable<any>{
        return this.http.post<any>(API_CHANGE_PASSWORD, requestData).pipe(catchError(e => throwError(() => new Error)))
    }
}