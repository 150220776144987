import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as html2pdf from 'html2pdf.js'
import { TbtDetails } from 'src/app/core/service/_ service/jsa-tbt-details.service';
import { JsaTBTdata, JsaTBTList } from 'src/app/core/_interfaces/tbt-details';


@Component({
  selector: 'app-tbt-report',
  templateUrl: './tbt-report.component.html',
  styleUrls: ['./tbt-report.component.scss']
})
export class TbtReportComponent implements OnInit {


  //public tableData: JsaTBTdata[] = [];
  issueNo: any;
  jsatbt: any;
  constructor(public dialogRef: MatDialogRef<TbtReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _tbtdetails: TbtDetails) { }

  ngOnInit(): void {
    console.log(this.data)
    // this.issueNo = this.data?.JsaTBTdata[0].tbtreportno
    // console.log(this.issueNo)

    this.issueNo = this.data.data;
    this.jsatbt = this.data.data.JsaTBTdata[0];
    console.log(this.jsatbt)
    console.log(this.issueNo);

    this.closePopup();
  }

  public closePopup() {
    this.dialogRef.close();
  }
  ngAfterViewInit() {

    this.samplePdf();

  }


  public samplePdf(): void {
    const options = {
      margin: [3, 10, 3, 10],
      name: 'output.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        dpi: 192,
        scale: 4, y: 0, scrollY: 0,
        letterRendering: true,
        useCORS: true
      },
      jsPDF: { unit: 'mm', orientation: 'portrait', format: 'a4' }
    }
    const element: Element = document.getElementById('samplepdf');
    html2pdf()
      .from(element)
      .set(options)
      .save('Tbt-Report.pdf');
    //this.spinner.hide();
  }

}
