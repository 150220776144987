import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as html2pdf from 'html2pdf.js'

@Component({
  selector: 'app-jsa-report',
  templateUrl: './jsa-report.component.html',
  styleUrls: ['./jsa-report.component.scss']
})
export class JsaReportComponent implements OnInit {
  public givenBy: any;
  constructor(private dialogRef: MatDialogRef<JsaReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.givenBy = this.data.jsaData.tbtdetails[0].givenbys
    console.log(this.givenBy.length)

    this.closePopup();
  }
  ngAfterViewInit() {

    this.samplePdf();
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public samplePdf(): void {
    const options = {
      margin: [3, 10, 3, 10],
      name: 'output.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true
      },
      jsPDF: { unit: 'mm', orientation: 'portrait', format: 'a4' }
    }
    const element: Element = document.getElementById('samplePdf');
    html2pdf()
      .from(element)
      .set(options)
      .save('jsa-Report.pdf');
    //this.spinner.hide();
  }



}


