<!-- <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <style>
        .table {
            border: 1px solid #141212;
            border-collapse: collapse;
            width: 100%;
        }

        .table td {
            border: 1px solid #141212;
        }
    </style>
</head>

<body>
    <div class="card" style="height:500px;overflow:scroll">
        <div id="samplePdf">
            <div style="width: 100%; height: 29.7cm;">
                <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                    <table class="table">
                        <tr>
                            <td rowspan='2' colspan='1' style="width: 21%; text-align: center;"><img
                                    src="assets/images/p7.png" style="width: 100%;" /></td>
                            <td rowspan='1' colspan='4'
                                style="width: 73%; font-size: 19px;text-align: center; font-weight:bold; padding: 3px;">
                                Dhariwal Infrastructure Limited</td>
                            <td rowspan='2' colspan='1'
                                style="width: 30%; font-size: 14px;text-align: center; font-weight:bold; padding: 3px;">
                                DIL_IMS_HSE_F_004</td>
                        </tr>
                        <tr>
                            <td rowspan='1' colspan='4'
                                style="text-align: center;font-size: 18px; font-weight:bold; padding: 3px; text-transform: uppercase;">
                                FORMAT
                                FOR
                                Add Equipment, Machinery, Tools & Tackle and Vehicles REPORTING</td>
                        </tr>
                        <tr style="background-color: gray;">
                            <td style="text-align: center; font-weight:bold; padding: 3px;">Issue No.</td>
                            <td style="width: 17%; text-align: center; font-weight:bold; padding: 3px;">Revision No.
                            </td>
                            <td style="width: 19%;text-align: center; font-weight:bold; padding: 3px;">Last revised on
                            </td>
                            <td style="width: 19%;text-align: center; font-weight:bold; padding: 3px;">Last reviewed on
                            </td>
                            <td style="width: 15%;text-align: center; font-weight:bold; padding: 3px;">File No.</td>
                            <td style="text-align: center; font-weight:bold; padding: 3px;">Page</td>
                        </tr>
                        <tr>
                            <td style="height: 30px;"></td>
                            <td style="height: 30px;"></td>
                            <td style="height: 30px;"></td>
                            <td style="height: 30px;"></td>
                            <td style="height: 30px;"></td>
                            <td style="height: 30px;"></td>
                        </tr>
                    </table>
                </div>
                <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                    <table class="table">
                        <tr>
                            <td style="padding: 3px; width: 15%;">Contractor Name</td>
                            <td style="padding: 3px; width: 35%;">{{data.euipmentData.contractor?.contractor}}
                            </td>
                            <td style="padding: 3px; width: 15%;">Unit</td>
                            <td style="padding: 3px; width: 35%;">{{data.euipmentData.unit?.unitname}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">System</td>
                            <td style="padding: 3px;">{{data.euipmentData.plant?.plantname}}</td>
                            <td style="padding: 3px;">Sub System</td>
                            <td style="padding: 3px;">{{data.euipmentData.subplant?.subplantname}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Equipment List</td>
                            <td style="padding: 3px;">{{data.euipmentData.equipmentkkscode?.equipmentname}}</td>
                            <td style="padding: 3px;">KKS Code</td>
                            <td style="padding: 3px;">{{data.euipmentData.kkscode?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Date</td>
                            <td style="padding: 3px;">{{data.euipmentData.entrydate?.value}}</td>
                            <td style="padding: 3px;">Equipment ID No/Reg. No.</td>
                            <td style="padding: 3px;">{{data.euipmentData.equipmentidno?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Equipment /Vehicle Type</td>
                            <td style="padding: 3px;">{{data.euipmentData.equipmenttype?.equiptype}}</td>
                            <td style="padding: 3px;">Previous Statutory Date</td>
                            <td style="padding: 3px;">{{data.euipmentData.createdon?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Make / Model</td>
                            <td style="padding: 3px;">{{data.euipmentData.make?.value}}</td>
                            <td style="padding: 3px;">Capacity</td>
                            <td style="padding: 3px;">{{data.euipmentData.capacity?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Capacity Unit</td>
                            <td style="padding: 3px;">{{data.euipmentData.capacityunit?.value}}</td>
                            <td style="padding: 3px;">TPI Competent Person Name</td>
                            <td style="padding: 3px;">{{data.euipmentData.tpicompetentpersonname?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Description</td>
                            <td style="padding: 3px;" colspan="3">{{data.euipmentData.description?.value}}</td>
                        </tr>
                    </table>
                </div>
                <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                    <table class="table">
                        <tr>
                            <td style="padding: 3px; width: 15%;">Insurance Valid Upto</td>
                            <td style="padding: 3px; width: 35%;">{{data.euipmentData.insurancevalidupto?.value}}</td>
                            <td style="padding: 3px; width: 15%;">Manufacturing Year</td>
                            <td style="padding: 3px; width: 35%;">{{data.euipmentData.manufacturingyear?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">PUC Valid Upto</td>
                            <td style="padding: 3px;">{{data.euipmentData.pucvalidupto?.value}}</td>
                            <td style="padding: 3px;">Permit / Tax Valid Upto</td>
                            <td style="padding: 3px;">{{data.euipmentData.permittaxvalidupto?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Oparator / Driver Aadhar Number</td>
                            <td style="padding: 3px;">{{data.euipmentData.operatordriveraadharnumber?.value}}</td>
                            <td style="padding: 3px;">Oparator / Driver Name</td>
                            <td style="padding: 3px;">{{data.euipmentData.operatordrivername?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Oparator / Driver DL No.</td>
                            <td style="padding: 3px;">{{data.euipmentData.operatordriverdlno?.value}}</td>
                            <td style="padding: 3px;">Oparator / Driver Category</td>
                            <td style="padding: 3px;">{{data.euipmentData.operatordrivercategory?.value}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Oparator / Driver DL Valid Up to</td>
                            <td style="padding: 3px;" colspan="3">{{data.euipmentData.operatordriverdlvalidupto?.value}}
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 3px;">Remarks</td>
                            <td style="padding: 3px;" colspan="3">{{data.euipmentData.remarks?.value}}</td>
                        </tr>
                    </table>
                </div>

                <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                    <table class="table">
                        <tr>
                            <td style="padding: 3px; text-align: center; width: 20px;">Sl.No.</td>
                            <td style="padding: 3px;">Items</td>
                            <td style="padding: 3px;">Observation</td>
                            <td style="padding: 3px;">Remarks</td>
                        </tr>
                        <tr *ngFor="let row of data.euipmentData.equipwiseobservations ;let i = index">
                            <td style="padding: 3px; text-align: center;">{{i+1}}</td>
                            <td style="padding: 3px;">{{row .items}}</td>
                            <td style="padding: 3px;">{{row .observation}}</td>
                            <td style="padding: 3px;">{{row .remarks}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</body>

</html> -->
<div class="card" style="height:500px;overflow:scroll">
    <div id="samplePdf">
        <div style="width: 100%; margin: 20px 0;">
            <table class="table" style="width: 100%; margin: 10px 0; font-weight: 600;">
                <tr>
                    <td rowspan='2' colspan='1' style="width: 21%; text-align: center; padding: 3px;"><img
                            src="assets/images/p7.png" style="width: 100%;" /></td>
                    <td rowspan='1' colspan='4'
                        style="width: 73%; font-size: 19px;text-align: center;font-weight:bold; padding: 6px;">Dhariwal
                        Infrastructure Limited</td>
                    <td rowspan='2' colspan='1'
                        style="width: 30%; font-size: 14px;text-align: center;font-weight:bold; padding: 6px;">
                        DIL_IMS_HSE_F_004</td>
                </tr>
                <tr>
                    <td rowspan='1' colspan='4'
                        style="text-align: center;font-size: 15px; font-weight:bold; padding: 6px;">FORMAT
                        FORMAT
                        FOR
                        Add Equipment, Machinery, Tools & Tackle and Vehicles REPORTING</td>
                </tr>
                <tr style="background-color: gray;">
                    <td style="text-align: center;font-weight:bold; padding: 5px;">Issue No.</td>
                    <td style="width: 17%; text-align: center;font-weight:bold; padding: 3px;">Revision No.</td>
                    <td style="width: 19%;text-align: center;font-weight:bold; padding: 3px;">Last revised on</td>
                    <td style="width: 19%;text-align: center;font-weight:bold; padding: 3px;">Last reviewed on</td>
                    <td style="width: 15%;text-align: center;font-weight:bold; padding: 3px;">File No.</td>
                    <td style="text-align: center;font-weight:bold; padding: 3px;">Page</td>
                </tr>
                <tr>
                    <td style="height: 30px;"></td>
                    <td style="height: 30px;"></td>
                    <td style="height: 30px;"></td>
                    <td style="height: 30px;"></td>
                    <td style="height: 30px;"></td>
                    <td style="height: 30px;"></td>
                </tr>
            </table>
            <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                <table class="table">
                    <tr>
                        <td style="padding: 3px; width: 15%;">Contractor Name</td>
                        <td style="padding: 3px; width: 35%;">{{data.euipmentData.contractor?.contractor}}
                        </td>
                        <td style="padding: 3px; width: 15%;">Unit</td>
                        <td style="padding: 3px; width: 35%;">{{data.euipmentData.unit?.unitname}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">System</td>
                        <td style="padding: 3px;">{{data.euipmentData.plant?.plantname}}</td>
                        <td style="padding: 3px;">Sub System</td>
                        <td style="padding: 3px;">{{data.euipmentData.subplant?.subplantname}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Equipment List</td>
                        <td style="padding: 3px;">{{data.euipmentData.equipmentkkscode?.equipmentname}}</td>
                        <td style="padding: 3px;">KKS Code</td>
                        <td style="padding: 3px;">{{data.euipmentData.kkscode?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Date</td>
                        <td style="padding: 3px;">{{data.euipmentData.entrydate?.value}}</td>
                        <td style="padding: 3px;">Equipment ID No/Reg. No.</td>
                        <td style="padding: 3px;">{{data.euipmentData.equipmentidno?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Equipment /Vehicle Type</td>
                        <td style="padding: 3px;">{{data.euipmentData.equipmenttype?.equiptype}}</td>
                        <td style="padding: 3px;">Previous Statutory Date</td>
                        <td style="padding: 3px;">{{data.euipmentData.prevstatutorydate?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Make / Model</td>
                        <td style="padding: 3px;">{{data.euipmentData.make?.value}}</td>
                        <td style="padding: 3px;">Capacity</td>
                        <td style="padding: 3px;">{{data.euipmentData.capacity?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Capacity Unit</td>
                        <td style="padding: 3px;">{{data.euipmentData.capacityunit?.value}}</td>
                        <td style="padding: 3px;">TPI Competent Person Name</td>
                        <td style="padding: 3px;">{{data.euipmentData.tpicompetentpersonname?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Description</td>
                        <td style="padding: 3px;" colspan="3">{{data.euipmentData.description?.value}}</td>
                    </tr>

                </table>
            </div>
            <div style="width: 100%; margin: 20px 0; font-weight: 600;" *ngIf="insurancediv">
                <table class="table">
                    <tr>
                        <td style="padding: 3px; width: 15%;">Insurance Valid Upto</td>
                        <td style="padding: 3px; width: 35%;">{{data.euipmentData.insurancevalidupto?.value}}</td>
                        <td style="padding: 3px; width: 15%;">Manufacturing Year</td>
                        <td style="padding: 3px; width: 35%;">{{data.euipmentData.manufacturingyear?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">PUC Valid Upto</td>
                        <td style="padding: 3px;">{{data.euipmentData.pucvalidupto?.value}}</td>
                        <td style="padding: 3px;">Permit / Tax Valid Upto</td>
                        <td style="padding: 3px;">{{data.euipmentData.permittaxvalidupto?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Oparator / Driver Aadhar Number</td>
                        <td style="padding: 3px;">{{data.euipmentData.operatordriveraadharnumber?.value}}</td>
                        <td style="padding: 3px;">Oparator / Driver Name</td>
                        <td style="padding: 3px;">{{data.euipmentData.operatordrivername?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Oparator / Driver DL No.</td>
                        <td style="padding: 3px;">{{data.euipmentData.operatordriverdlno?.value}}</td>
                        <td style="padding: 3px;">Oparator / Driver Category</td>
                        <td style="padding: 3px;">{{data.euipmentData.operatordrivercategory?.value}}</td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Oparator / Driver DL Valid Up to</td>
                        <td style="padding: 3px;" colspan="3">{{data.euipmentData.operatordriverdlvalidupto?.value}}
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 3px;">Remarks</td>
                        <td style="padding: 3px;" colspan="3">{{data.euipmentData.remarks?.value}}</td>
                    </tr>
                </table>
            </div>
            <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                <table class="table">
                    <tr>
                        <td style="padding: 3px; text-align: center; width: 20px;">Sl.No.</td>
                        <td style="padding: 3px; text-align: center;">Items</td>
                        <td style="padding: 3px; text-align: center;">Observation</td>
                        <td style="padding: 3px; text-align: center;">Remarks</td>
                    </tr>
                    <tr *ngFor="let row of data.euipmentData.equipwiseobservations ;let i = index">
                        <td style="padding: 3px; text-align: center;">{{i+1}}</td>
                        <td style="padding: 3px;">{{row .items}}</td>
                        <td style="padding: 3px; text-align: center;">{{ row.observation === 1 ? 'YES' : row.observation
                            === 2 ? 'NO' :
                            'N/A' }}</td>

                        <td style="padding: 3px; text-align: center;">{{row .remarks}}</td>
                    </tr>

                </table>
            </div>
        </div>
    </div>
</div>