<div class="card" style="height:500px;overflow:scroll">
  <div id="samplePdf">
    <div style="width: 100%; height: 29.7cm;">
      <div style="width: 100%; margin: 20px 0; font-weight: 600;">
        <table class="table">
          <tr>
            <td rowspan='2' colspan='1' style="width: 21%; text-align: center;"><img src="assets/images/p7.png"
                style="width: 100%;" /></td>
            <td rowspan='1' colspan='4'
              style="width: 73%; font-size: 19px;text-align: center; font-weight:bold; padding: 3px;">
              Dhariwal Infrastructure Limited</td>
            <td rowspan='2' colspan='1'
              style="width: 30%; font-size: 14px;text-align: center; font-weight:bold; padding: 3px;">
              DIL_IMS_HSE_F_004</td>
          </tr>
          <tr>
            <td rowspan='1' colspan='4' style="text-align: center;font-size: 18px; font-weight:bold; padding: 3px;">
              FORMAT
              FOR
              INCIDENT INVESTIGATION REPORTING</td>
          </tr>
          <tr style="background-color: gray;">
            <td style="text-align: center; font-weight:bold; padding: 3px;">Issue No.</td>
            <td style="width: 17%; text-align: center; font-weight:bold; padding: 3px;">Revision No.</td>
            <td style="width: 19%;text-align: center; font-weight:bold; padding: 3px;">Last revised on</td>
            <td style="width: 19%;text-align: center; font-weight:bold; padding: 3px;">Last reviewed on</td>
            <td style="width: 15%;text-align: center; font-weight:bold; padding: 3px;">File No.</td>
            <td style="text-align: center; font-weight:bold; padding: 3px;">Page</td>
          </tr>
          <tr>
            <td style="height: 30px;"></td>
            <td style="height: 30px;"></td>
            <td style="height: 30px;"></td>
            <td style="height: 30px;"></td>
            <td style="height: 30px;"></td>
            <td style="height: 30px;"></td>
          </tr>

        </table>
      </div>
      <div style="width: 100%; margin: 20px 0; font-weight: 600;">
        <table class="table">
          <tr>
            <td colspan="4" style="padding: 3px;">Name, Age & Designation of Injured</td>
            <td colspan="4" style="padding: 3px;">
              {{data.incidentData.nameofinjured.value+','+data.incidentData.ageofinjured.value+','+data.incidentData.designationofinjured.value}}
            </td>
          </tr>
          <tr>
            <td colspan="4" style="padding: 3px;">Date & Time of Incident</td>
            <td colspan="4" style="padding: 3px;">{{data.incidentData.incidentoccuredon.value}}</td>
          </tr>
          <tr>
            <td colspan="4" style="padding: 3px;">>Department/Contractor's Name</td>
            <td colspan="4" style="padding: 3px;">{{data.incidentData.ageofinjured.value}}</td>
          </tr>
          <tr>
            <td colspan="4" style="padding: 3px;">Location of Incident</td>
            <td colspan="4" style="padding: 3px;">{{data.incidentData.locationofincident.value}}</td>
          </tr>
          <tr>
            <td colspan="4" style="padding: 3px;">Nature of injury/Damage</td>
            <td colspan="4" style="padding: 3px;"> {{data.incidentData.natureofinjury.value}}</td>
          </tr>
          <tr>
            <td colspan="4" style="padding: 3px;">Name of Witness/Person Who First Witnessed</td>
            <td colspan="4" style="padding: 3px;">{{data.incidentData.nameofwitness.value}}</td>
          </tr>
          <tr>
            <td style="width:5%; padding: 3px;"></td>
            <td style="width:20%; padding: 3px;">First Aid Injury</td>
            <td style="width:5%; padding: 3px;"></td>
            <td style="width:20%; padding: 3px;"> Lost Time Injury</td>
            <td style="width:5%; padding: 3px;"></td>
            <td style="width:20%; padding: 3px;">Fatality</td>
            <td style="width:5%; padding: 3px;"></td>
            <td style="width:20%; padding: 3px;">Property Damage</td>
          </tr>
          <tr style="margin-top:0;">
            <td colspan='3' style="height: 90px; padding: 3px;">Brief description of incident</td>
            <td colspan='5' style="height: 90px; padding: 3px;">{{data.incidentData.briefdescriptionofincident.value}}
            </td>
          </tr>
          <tr>
            <td colspan='3' style="height: 90px; padding: 3px;">Observation</td>
            <td colspan='5' style="height: 90px; padding: 3px;">
              {{data.incidentData.observationofactivityincharge.value}}
            </td>
          </tr>
          <tr>
            <td colspan='3' style="height: 90px; padding: 3px;">Direct Causes</td>
            <td colspan='5' style="height: 90px; padding: 3px;">{{data.incidentData.directcauses.value}}</td>
          </tr>
          <tr>
            <td colspan='3' style="height: 50px; padding: 3px;">Root Causes</td>
            <td colspan='5' style="height: 50px; padding: 3px;">{{data.incidentData.rootcauses.value}}</td>
          </tr>
          <tr>
            <td colspan='8' style="padding: 3px;">A) CORECTIVE & PREVENTIV ACTIONS</td>
          </tr>
          <tr>
            <td style="padding: 3px;">Sr.No</td>
            <td colspan="3" style="padding: 3px;">Description</td>
            <td colspan="2" style="padding: 3px;">Action By</td>
            <td colspan="2" style="padding: 3px;">Target Date</td>
          </tr>
          <tr *ngFor="let item of data.incidentData.CAPADetails;let i = index">
            <td style="padding: 3px;">{{i+1}}</td>
            <td colspan="3" style="padding: 3px;">{{item.details}}</td>
            <td colspan="2" style="padding: 3px;">{{item.name}}</td>
            <td colspan="2" style="padding: 3px;">{{item.targetdate}}</td>
          </tr>
          <tr>
            <td colspan='8' style="padding: 3px;">B) CLASSIFICATION OF ACCIDENT AS PER IS 3786 </td>
          </tr>
          <tr>
            <td style="padding: 3px;">1</td>
            <td colspan="4" style="padding: 3px;">Classification According to agency</td>
            <td colspan="3" style="padding: 3px;">{{data.incidentData.classificationaccortoagency.value}}</td>
          </tr>
          <tr>
            <td style="padding: 3px;">2</td>
            <td colspan="4" style="padding: 3px;">Unsafe material or physical condition</td>
            <td colspan="3" style="padding: 3px;">{{data.incidentData.unsafematerialorphysicalcondi.value}}</td>
          </tr>
          <tr>
            <td style="padding: 3px;">3</td>
            <td colspan="4" style="padding: 3px;">The Unsafe act</td>
            <td colspan="3" style="padding: 3px;"></td>
          </tr>
          <tr>
            <td style="padding: 3px;">4</td>
            <td colspan="4" style="padding: 3px;">Unsafe Personal Factor</td>
            <td colspan="3" style="padding: 3px;">{{data.incidentData.unsafepersonalfactor.value}}</td>
          </tr>
          <tr>
            <td style="padding: 3px;">5</td>
            <td colspan="4" style="padding: 3px;">Type of Accident</td>
            <td colspan="3" style="padding: 3px;"></td>
          </tr>
          <tr>
            <td style="padding: 3px;">6</td>
            <td colspan="4" style="padding: 3px;">Nature of injury</td>
            <td colspan="3" style="padding: 3px;">{{data.incidentData.natureofinjury.value}}</td>
          </tr>
        </table>

      </div>
    </div>
    <!-- page-2 -->
    <div>
      <div style="width: 100%; margin: 20px 0; font-weight: 600;">
        <table class="table">
          <tr>
            <td rowspan='2' colspan='1' style="width: 21%; text-align: center;"><img src="assets/images/p7.png"
                style="width: 100%;" /></td>
            <td rowspan='1' colspan='4'
              style="width: 73%; font-size: 19px;text-align: center; font-weight:bold; padding: 3px;">
              Dhariwal Infrastructure Limited</td>
            <td rowspan='2' colspan='1'
              style="width: 30%; font-size: 14px;text-align: center; font-weight:bold; padding: 3px;">
              DIL_IMS_HSE_F_004</td>
          </tr>
          <tr>
            <td rowspan='1' colspan='4' style="text-align: center;font-size: 18px; font-weight:bold; padding: 3px;">
              FORMAT
              FOR
              INCIDENT INVESTIGATION REPORTING</td>
          </tr>
          <tr style="background-color: gray;">
            <td style="text-align: center; font-weight:bold; padding: 3px;">Issue No.</td>
            <td style="width: 17%; text-align: center; font-weight:bold; padding: 3px;">Revision No.</td>
            <td style="width: 19%; text-align: center; font-weight:bold; padding: 3px;">Last revised on</td>
            <td style="width: 19%; text-align: center; font-weight:bold; padding: 3px;">Last reviewed on</td>
            <td style="width: 15%; text-align: center; font-weight:bold; padding: 3px;">File No.</td>
            <td style="text-align: center; font-weight:bold; padding: 3px;">Page</td>
          </tr>
          <tr>
            <td style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
        </table>
      </div>
      <div style="width: 100%; margin: 20px 0;">
        <table class="table">
          <tr>
            <td style="padding: 3px; text-align:center; width: 10%;">7</td>
            <td style="padding: 3px;" colspan="3">Location of the injury</td>
            <td style="width: 40%;">{{data.incidentData.locationoftheinjury.value}}</td>
          </tr>
          <tr>
            <td colspan='5' style="padding: 3px;">C)
              WHY - WHY ANALYSIS
            </td>
          </tr>
          <tr>
            <td colspan='5' style="padding: 3px;">INCIDENT
              -

            </td>
          </tr>
          <tr>
            <td colspan='5' style="padding: 3px;"> 1) WHY?

            </td>
          </tr>
          <tr>
            <td colspan='5' style="padding: 3px;">

            </td>
          </tr>
          <tr>
            <td colspan='5' style="font-size: 14px;text-align: left; font-weight:bold; padding: 3px;"> 2) WHY?

            </td>
          </tr>
          <tr>
            <td colspan='5' style="padding: 3px;">

            </td>
          </tr>
          <tr>
            <td colspan='5' style="font-size: 14px;text-align: left; font-weight:bold; padding: 3px;"> 3) WHY?

            </td>
          </tr>
          <tr>
            <td colspan='5' style="padding: 3px;">

            </td>
          </tr>
          <tr>
            <td colspan='5' style="font-size: 14px;text-align: left; font-weight:bold; padding: 3px;"> 4) WHY?

            </td>
          </tr>
          <tr>
            <td colspan='5' style="padding: 3px;">

            </td>
          </tr>
          <tr>
            <td colspan='5' style="font-size: 14px;text-align: left; font-weight:bold; padding: 3px;"> 5) WHY?

            </td>
          </tr>
          <tr>
            <td colspan='5' style="padding: 3px;">

            </td>
          </tr>
          <tr>
            <td colspan='6' style="font-size: 14px; font-weight:bold; padding: 3px;"> Investigation Team :

            </td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 3px;">Name</td>
            <td style="padding: 3px;">Sign</td>
            <td style="padding: 3px;">Date</td>
          </tr>
          <tr *ngFor="let item of data.incidentData.IncidentTeam">
            <td colspan="3" style="padding: 3px;">{{item.name}}</td>
            <td style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
          <tr>
            <td colspan='5' style="height: 30px;width:50%;text-align: center; font-weight:bold; padding: 3px;"></td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 3px;">REVIEW ON FOR COMPLIANCE</td>
            <td style="padding: 3px;">REMARK</td>
            <td style="padding: 3px;">SIGN BY</td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 3px;">{{data.incidentData.reviewonforcompliance.value}}</td>
            <td style="padding: 3px;"></td>
            <td style="padding: 3px;"></td>
          </tr>
        </table>
      </div>
    </div>

  </div>