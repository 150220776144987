import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsaReportComponent } from './jsa-report.component';



@NgModule({
  declarations: [JsaReportComponent],
  imports: [
    CommonModule
  ],
  exports:[
    JsaReportComponent
  ]
})
export class JsaReportModule { }
