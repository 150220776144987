import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TbtReportComponent } from './tbt-report.component';

import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser'

@NgModule({
  declarations: [TbtReportComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule
  ],
  exports: [
    TbtReportComponent
  ]
})
export class TbtReportModule { }
