<!-- <p>change-password works!</p> -->
<app-header [sharePageTitle]="sharePageTitle"></app-header>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="formData">
                            <div class="col-md-6">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label> Old Password</mat-label>
                                    <input matInput autocomplete="off" formControlName="oldpassword"
                                        [type]="hide3 ? 'password' : 'text'" required>
                                    <mat-icon matSuffix (click)="hide3 = !hide3">
                                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    <mat-error *ngIf="formData.get('oldpassword')?.hasError('required')">
                                        Confirmpassword is required
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>New Password</mat-label>
                                    <input matInput autocomplete="off" formControlName="newpassword"
                                        [type]="hide ? 'password' : 'text'" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$">
                                    <mat-icon matSuffix (click)="hide = !hide">
                                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    <mat-error *ngIf="formData.get('newpassword')?.hasError('required')">
                                        Newpassword is required
                                    </mat-error>
                                </mat-form-field>
                                <span style="color:red"><b>Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</b></span>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label> Confirm New Password</mat-label>
                                    <input matInput autocomplete="off" formControlName="confirmpassword"
                                        [type]="hide1 ? 'password' : 'text'" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$">
                                    <mat-icon matSuffix (click)="hide1 = !hide1">
                                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    <mat-error *ngIf="formData.get('confirmpassword')?.hasError('required')">
                                        Confirmpassword is required
                                    </mat-error>
                                      <!-- <div style="color:red" *ngIf="errorMsg">Passwords do not match</div> -->
                                </mat-form-field>

                            </div>
                        </form>
                        <div class="button-set m-t-20">
                            <div class="button-set-left">
                                <button type="button" class="btn btn-primary" [disabled]="formData.status == 'INVALID'" (click)="ChangePassword()"><span class="material-icons">done</span>
                                    Save </button>
                                <button type="button" class="btn btn-outline-secondary" (click)="cancel()"><span
                                        class="material-icons">close</span>
                                    Cancel</button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>
</section>