import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, throwError } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { API_EMPLOYEE_DETAILS, API_GET_EQUIPMENT_MASTER, API_GET_KKS, API_GET_PLANT_LIST, API_GET_SUBPLANT_LIST, API_JSA_TBT_DETAILS, API_JSA_TBT_ENTRY, API_JSA_TBT_REPORT, API_MASTERLISTING, EDIT_JSA_TBT_DETAILS } from "../../constant";
import { IncidentApproveOutPut } from "../../_interfaces/approved-incident";
import { Equipmentlist, KKSList, PlantList, SubPlantList } from "../../_interfaces/jsa-entry";
import { JSAMasterListing } from "../../_interfaces/jsa-masterlist";
import { JsaTBTList, TBTDetails, TbtEntry, TbtEntryUserList } from "../../_interfaces/tbt-details";
import { TbtReportPrint } from "../../_interfaces/tbt-report-print";

@Injectable({
    providedIn: "root"
})
export class TbtDetails {
    constructor(
        private _http: HttpClient
    ) { }
    getJSAEntyPlantListing(requestData: any): Observable<PlantList> {
        return this._http.post<PlantList>(API_GET_PLANT_LIST, requestData).pipe(catchError(e => throwError(() => new Error(e))))
    }
    getJSAEntySubPlantListing(requestData: any): Observable<SubPlantList> {
        return this._http.post<SubPlantList>(API_GET_SUBPLANT_LIST, requestData).pipe(catchError(e => throwError(() => new Error(e))))
    }
    getJSAEntyEqupmentListing(requestData: any): Observable<Equipmentlist> {
        return this._http.post<Equipmentlist>(API_GET_EQUIPMENT_MASTER, requestData).pipe(catchError(e => throwError(() => new Error(e))))
    }
    getJSAKKSCode(requestData: any): Observable<KKSList> {
        return this._http.post<KKSList>(API_GET_KKS, requestData).pipe(catchError(e => throwError(() => new Error(e))))
    }
    getJSAtbt(JsonData: any): Observable<JsaTBTList> {
        return this._http.post<JsaTBTList>(API_JSA_TBT_DETAILS, JsonData).pipe(catchError((e) => throwError(() => new Error(e))))
    }
    getJSAMasterListing(): Observable<JSAMasterListing> {
        return this._http.get<JSAMasterListing>(API_MASTERLISTING).pipe(catchError(e => throwError(() => new Error(e))))
    }
    tbtentry(jsonData: TbtEntry): Observable<IncidentApproveOutPut> {
        return this._http.post<IncidentApproveOutPut>(API_JSA_TBT_ENTRY, jsonData)
    }
    getEmployee(jsonData: any): Observable<TbtEntryUserList> {
        return this._http.post<TbtEntryUserList>(API_EMPLOYEE_DETAILS, jsonData).pipe(catchError(e => throwError(() => new Error(e))))
    }
    getJSATBTDetails(jsonData: any): Observable<TBTDetails> {
        return this._http.post<TBTDetails>(EDIT_JSA_TBT_DETAILS, jsonData).pipe(catchError(e => throwError(() => new Error(e))))
    }
    getJSAtbtReport(JsonData: any): Observable<TbtReportPrint> {
        return this._http.post<TbtReportPrint>(API_JSA_TBT_REPORT, JsonData).pipe(catchError((e) => throwError(() => new Error(e))))
    }
}
