import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as html2pdf from 'html2pdf.js'

@Component({
  selector: 'app-equipment-report',
  templateUrl: './equipment-report.component.html',
  styleUrls: ['./equipment-report.component.scss']
})
export class EquipmentReportComponent implements OnInit {
  public a: any
  details: any;
  public insurancediv: any;
  constructor(private dialogRef: MatDialogRef<EquipmentReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }


  ngOnInit(): void {
    this.details = this.data.euipmentData.equipwiseobservations
    this.closePopup();
    this.insuranceDivValidation();

  }
  insuranceDivValidation() {
    if (this.data.euipmentData.equipmenttype?.equipgroupid == 1) {
      this.insurancediv = false;
    }
    if (this.data.euipmentData.equipmenttype?.equipgroupid == 2) {
      this.insurancediv = true;
    }
  }


  ngAfterViewInit() {
    if (this.data.euipmentData) {
      this.samplePdf();
    }
  }
  public closePopup() {
    this.dialogRef.close();
  }
  public samplePdf(): void {
    const options = {
      margin: [3, 10, 3, 10],
      name: 'output.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true
      },
      jsPDF: { unit: 'mm', orientation: 'portrait', format: 'a4' }
    }
    const element: Element = document.getElementById('samplePdf');


    html2pdf()
      .from(element)
      .set(options)
      .save('Equipment-Details-Report.pdf');
    //this.spinner.hide();

  }

  // customDate(): string {
  //   let d = new Date();
  //   var year = d.getFullYear();
  //   var month = d.getMonth() + 1;
  //   var dt = d.getDate();
  //   return dt + "_" + month + "_" + year;
  // }

  // myCallback(pdf) {

  // }
  // public samplePdf(): void {
  //   const options = {
  //     margin: [1, 18, 12, 2],
  //     name: 'output.pdf',
  //     image: { type: 'jpeg' },
  //     html2canvas: { dpi: 192, letterRendering: true, imageTimeout: 0, removeContainer: true },
  //     jsPDF: { unit: 'mm', orientation: 'portrait', format: 'a4' },
  //     pdfCallback: this.myCallback
  //   }
  //   const element: Element = document.getElementById('samplePdf');



  //   html2pdf().from(element).set(options).toPdf().get('pdf').then(function (pdf) {
  //     var totalPages = pdf.internal.getNumberOfPages();
  //     for (let i = 1; i <= totalPages; i++) {
  //       pdf.setPage(i);
  //       pdf.setFontSize(10);
  //       pdf.setTextColor('#000');
  //       //set page number in every page
  //       pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 5);
  //     }
  //   }).save("Job-Order.pdf");


  // }
}
