import { RouteInfo } from './sidebar.metadata';
export const Routes: RouteInfo[] = [
      {
            path: 'dashboard',
            title: 'Dashboard',
            moduleName: 'dashboard',
            icon: 'fab fa-envira',
            class: '',
            groupTitle: false,
            submenu: []
      },
      {
            path: '',
            title: 'Incident',
            moduleName: 'incident',
            icon: 'fas fa-tachometer-alt',
            class: 'menu-toggle',
            groupTitle: false,
            submenu: [
                  {
                        path: 'incident/incident-list',
                        title: 'Incident List',
                        moduleName: '',
                        icon: '',
                        class: 'ml-menu',
                        groupTitle: false,
                        submenu: []
                  },
                  // {
                  //   path: 'incident/incident-entry',
                  //   title: 'Incident Entry',
                  //   moduleName: '',
                  //   icon: '',
                  //   class: 'ml-menu',
                  //   groupTitle: false,
                  //   submenu: []
                  // },
                  // {
                  //   path: 'incident/incident-investigation',
                  //   title: 'Incident Investigation',
                  //   moduleName: '',
                  //   icon: '',
                  //   class: 'ml-menu',
                  //   groupTitle: false,
                  //   submenu: []
                  // }
            ]
      },
      {
            path: '',
            title: 'JSA',
            moduleName: 'jsa',
            icon: 'fas fa-tachometer-alt',
            class: 'menu-toggle',
            groupTitle: false,
            submenu: [
                  {
                        path: 'jsa/jsa-list',
                        title: 'JSA List',
                        moduleName: '',
                        icon: '',
                        class: 'ml-menu',
                        groupTitle: false,
                        submenu: []
                  },
            ]
      },
      {
            path: '',
            title: 'Equipment',
            moduleName: 'equipment',
            icon: 'fas fa-tools',
            class: 'menu-toggle',
            groupTitle: false,
            submenu: [
                  {
                        path: '/equipment/pre-deployment-inspection',
                        title: 'List of Equipment, Machinery, Tools & Tackle (Pre-deployment Inspection)',
                        moduleName: 'equipment',
                        icon: '',
                        class: 'ml-menu',
                        groupTitle: false,
                        submenu: []
                  },
                  {
                        path: '/equipment/list-of-vehicles-post-development',
                        title: 'List of Vehicles (Post-deployment Inspection)',
                        moduleName: 'equipment',
                        icon: '',
                        class: 'ml-menu',
                        groupTitle: false,
                        submenu: []
                  },
                  {
                        path: '/equipment/list-of-equipment-tools-tackle',
                        title: 'List of Equipment, Machinery, Tools & Tackle (Post-deployment Inspection)',
                        moduleName: 'equipment',
                        icon: '',
                        class: 'ml-menu',
                        groupTitle: false,
                        submenu: []
                  }
            ]
      },
      {
            path: '',
            title: 'Reports',
            moduleName: 'report',
            icon: 'fas fa-file-alt',
            class: 'menu-toggle',
            groupTitle: false,
            submenu: [
                  {
                        path: '/report/safety-report-incident',
                        title: 'Safety Report Incident',
                        moduleName: 'report',
                        icon: '',
                        class: 'ml-menu',
                        groupTitle: false,
                        submenu: []
                  },
                  {
                        path: '/report/jsa-report-v2',
                        title: 'Jsa Report',
                        moduleName: 'report',
                        icon: '',
                        class: 'ml-menu',
                        groupTitle: false,
                        submenu: []
                  }
            ]
      },
      {
            path: '',
            title: 'Manage User',
            moduleName: 'manage-user',
            icon: 'fas fa-stamp',
            class: 'menu-toggle',
            groupTitle: false,
            submenu: [
                  {
                        path: '/manage-user/user-list/',
                        title: 'User Details',
                        moduleName: '',
                        icon: '',
                        class: 'ml-menu',
                        groupTitle: false,
                        submenu: []
                  },
                  // {
                  //   path: '/report/jsa-report-v2',
                  //   title: 'Jsa Report',
                  //   moduleName: 'report',
                  //   icon: '',
                  //   class: 'ml-menu',
                  //   groupTitle: false,
                  //   submenu: []
                  // }
            ]
      },
]