import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser'
import { EquipmentReportComponent } from './equipment-report.component';



@NgModule({
  declarations: [EquipmentReportComponent],
  imports: [
    CommonModule,
    BrowserModule
  ],
  // exports: [
  //   EquipmentReportComponent
  // ]
})
export class EquipmentReportModule { }
