<div class="card" style="height:500px;overflow:scroll">
    <div id="samplePdf">
        <div style="width: 100%; margin: 20px 0;">
            <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                <table class="table" style="width: 100%; margin: 10px 0; font-weight: 600; border: 0;">
                    <thead>
                        <tr>
                            <td rowspan='2' colspan='1' style="width: 21%; text-align: center; padding: 3px;"><img
                                    src="assets/images/p7.png" style="width: 100%;" /></td>
                            <td rowspan='1' colspan='4'
                                style="width: 73%; font-size: 19px;text-align: center;font-weight:bold; padding: 6px;">
                                Dhariwal
                                Infrastructure Limited</td>
                            <td rowspan='2' colspan='1'
                                style="width: 30%; font-size: 14px;text-align: center;font-weight:bold; padding: 6px;">
                                DIL_IMS_MR_F_ 33 </td>
                        </tr>
                        <tr>
                            <td rowspan='1' colspan='4'
                                style="text-align: center;font-size: 15px; font-weight:bold; padding: 6px;">FORMAT
                                FOR JSA
                                REPORTING</td>
                        </tr>
                        <tr style="background-color: gray;">
                            <td style="text-align: center;font-weight:bold; padding: 5px;">Issue No.</td>
                            <td style="width: 17%; text-align: center;font-weight:bold; padding: 3px;">Revision No.</td>
                            <td style="width: 19%;text-align: center;font-weight:bold; padding: 3px;">Last revised on
                            </td>
                            <td style="width: 19%;text-align: center;font-weight:bold; padding: 3px;">Last reviewed on
                            </td>
                            <td style="width: 15%;text-align: center;font-weight:bold; padding: 3px;">File No.</td>
                            <td style="text-align: center;font-weight:bold; padding: 3px;">Page</td>
                        </tr>
                        <tr>
                            <td style="height: 30px; text-align: center;">1</td>
                            <td style="height: 30px; text-align: center;">0</td>
                            <td style="height: 30px; text-align: center;"></td>
                            <td style="height: 30px; text-align: center;">9.12.2014</td>
                            <td style="height: 30px; text-align: center;">MR_F_16</td>
                            <td style="height: 30px; text-align: center;">1 of 2</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="6" style="border: 0; padding: 0;">
                                <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                                    <table class="table">
                                        <tr style="font-weight:bold;width: 100%;">JSA HEADER</tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Jsa No</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.jsaautogenno.value}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Title</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.title.value}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Date</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.jsadate.value}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Contractor's Name</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.contractor.contractor}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Contractor Supervisor Name</td>
                                            <td colspan="6" style="padding: 3px;">
                                                {{data.jsaData.contractorsupname.value}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Department</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.department.department}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Unit</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.unit.unitname}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">System</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.plant.plantname}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Sub System</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.subplant.subplantname}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Equipment List</td>
                                            <td colspan="6" style="padding: 3px;">
                                                {{data.jsaData.equipment.equipmentname}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">KKS Code</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.kkscode.value}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Activity Area</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.activityarea.value}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 3px;">Work Description</td>
                                            <td colspan="6" style="padding: 3px;">{{data.jsaData.workdescription.value}}
                                            </td>
                                        </tr>
                                        <tr style="font-weight:bold;width: 100%;">JSA ACTIVITY</tr>
                                        <tr>
                                            <td colspan="1" style="padding: 3px;">AIN</td>
                                            <td colspan="2" style="padding: 3px;">ACTIVITY</td>
                                            <td colspan="2" style="padding: 3px;">HAZARD</td>
                                            <td colspan="2" style="padding: 3px;">PRECAUTIONS</td>
                                        </tr>
                                        <tr *ngFor="let data of data.jsaData.activitydtls ;let i = index">
                                            <td colspan="1" style="padding: 3px;">{{i+1}}</td>
                                            <td colspan="2" style="padding: 3px;">{{data.jsaactivity}}</td>
                                            <td colspan="2" style="padding: 3px;">
                                                <div [innerHTML]="data.hazarddetails"></div>
                                            </td>
                                            <td colspan="2" style="padding: 3px;">
                                                <div [innerHTML]="data.precautions"></div>
                                            </td>
                                        </tr>

                                    </table>
                                </div>

                                <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                                    <div class="row other-pages" style="page-break-before: always;"></div>

                                    <!-- <table class="table" style="margin-bottom: 90px;"
                                        *ngFor="let item of data.jsaData.tbtdetails;let i = index">
                                        <tr style="font-weight:bold;width: 100%;">TBT NO : {{i+1}}</tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Area/Location</td>
                                            <td colspan="6" style="padding: 3px;">{{item.activityarea}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Date</td>
                                            <td colspan="6" style="padding: 3px;">{{item.tbt_date}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Department</td>
                                            <td colspan="6" style="padding: 3px;">{{item.department.department}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Ptw No</td>
                                            <td colspan="6" style="padding: 3px;">{{item.ptwno}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Contractor</td>
                                            <td colspan="6" style="padding: 3px;">{{item.contractor.contractor}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Unit</td>
                                            <td colspan="6" style="padding: 3px;">{{item.unit.unitname}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;width: 50%;">System</td>
                                            <td colspan="6" style="padding: 3px;">{{item.plant.plantname}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Sub System</td>
                                            <td colspan="6" style="padding: 3px;">{{item.subplant.subplantname}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Sub System</td>
                                            <td colspan="6" style="padding: 3px;">{{item.equipmentkkscode.equipmentname}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Equipment List</td>
                                            <td colspan="6" style="padding: 3px;">{{item.equipmentkkscode.equipmentname}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;">Kkc Code</td>
                                            <td colspan="6" style="padding: 3px;">{{item.kkscode}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;width: 50%;">Topic Discussed</td>
                                            <td colspan="6" style="padding: 3px;">{{item.topicdiscussed}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="padding: 3px;text-align: center;">Employee Code</td>
                                            <td colspan="4" style="padding: 3px;text-align: center;">Employee Name</td>
                                        </tr>
                                        <tr *ngFor="let data of item.tbtparticipants">
                                            <td colspan="4" style="padding: 3px;text-align: center;">{{data.participantcode}}</td>
                                            <td colspan="4" style="padding: 3px;text-align: center;">{{data.participantname}}</td>
                                        </tr>
                                    </table> -->
                                    <!-- <table class="table" style="width: 100%; margin: 10px 0; font-weight: 600;">
                                        <tr>
                                            <td rowspan='2' colspan='1' style="width: 21%; text-align: center; padding: 3px;"><img
                                                    src="assets/images/p7.png" style="width: 100%;" /></td>
                                            <td rowspan='1' colspan='4'
                                                style="width: 73%; font-size: 19px;text-align: center;font-weight:bold; padding: 6px;">
                                                Dhariwal
                                                Infrastructure Limited</td>
                                            <td rowspan='2' colspan='1'
                                                style="width: 30%; font-size: 14px;text-align: center;font-weight:bold; padding: 6px;">
                                                DIL_IMS_MR_F_ 14</td>
                                        </tr>
                                        <tr>
                                            <td rowspan='1' colspan='4'
                                                style="text-align: center;font-size: 15px; font-weight:bold; padding: 6px;">TOOL
                                                BOX
                                                TALK</td>
                                        </tr>
                                        <tr style="background-color: gray;">
                                            <td style="text-align: center;font-weight:bold; padding: 5px;">Issue No.</td>
                                            <td style="width: 17%; text-align: center;font-weight:bold; padding: 3px;">Revision No.</td>
                                            <td style="width: 19%;text-align: center;font-weight:bold; padding: 3px;">Last revised on</td>
                                            <td style="width: 19%;text-align: center;font-weight:bold; padding: 3px;">Last reviewed on</td>
                                            <td style="width: 15%;text-align: center;font-weight:bold; padding: 3px;">File No.</td>
                                            <td style="text-align: center;font-weight:bold; padding: 3px;">Page</td>
                                        </tr>
                                        <tr>
                                            <td style="height: 30px; text-align: center;">1</td>
                                            <td style="height: 30px; text-align: center;">0</td>
                                            <td style="height: 30px; text-align: center;"></td>
                                            <td style="height: 30px; text-align: center;">9.12.2014</td>
                                            <td style="height: 30px; text-align: center;">MR_F_16</td>
                                            <td style="height: 30px; text-align: center;">2 of 2</td>
                                        </tr>
                                    </table> -->
                                    <div style="width: 100%; margin: 20px 0; font-weight: 600;">
                                        <table class="table table2" *ngFor="let item of data.jsaData.tbtdetails;">

                                            <tr>
                                                <td style="width: 5%;"></td>
                                                <td style="width: 15%;"></td>
                                                <td style="width: 28%;"></td>
                                                <td style="width: 12%;"></td>
                                                <td style="width: 15%;"></td>
                                                <td style="width: 25%;"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="padding: 3px; text-align: left;">JSA
                                                    NO:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{data.jsaData.jsaautogenno.value}}

                                                </td>

                                            </tr>
                                            <tr>
                                                <td colspan="3" style="padding: 3px;">Area/Location
                                                    :&nbsp;&nbsp;&nbsp;{{item.activityarea}}
                                                </td>
                                                <td colspan="3" style="padding: 3px;">Date :&nbsp;&nbsp;&nbsp;
                                                    {{item.tbt_date}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style="padding: 3px;" rowspan="2">Department:
                                                    {{item.department.department}}
                                                </td>
                                                <td colspan="3" style="padding: 3px;">PTW no(If any): &nbsp;&nbsp;&nbsp;
                                                    {{item.ptwno}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="padding: 3px;">Working Agency(If
                                                    any):&nbsp;&nbsp;&nbsp;{{item.workingagency}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="padding: 3px; text-align: left;">
                                                    <u style="font-size: 14px; display: block;">Topic Discussed:</u>

                                                </td>

                                            </tr>
                                            <tr>
                                                <td colspan="6" style="padding: 3px;"><u>Safety Instruction:</u><br>
                                                    1.Use Nose Mask,Saftey Helmet,Saftey Shoes,Hand Glove and Other
                                                    necessary PPE s if
                                                    any.<br>
                                                    2. While working do not use mobile phones for Personal Calls or any
                                                    Multi Media or
                                                    Social Media<br>
                                                    3. Do House Keeping After completion of Job.<br>
                                                    4. Use Proper Tools<br>
                                                    5.Please Check PTW Description and understand it before attending
                                                    the Job<br>
                                                    6.Use harness while doing job at height above 3m. Check lifeline
                                                    wherever there is no
                                                    space to hang the <br>
                                                    safety belt properly

                                                </td>



                                            </tr>
                                        </table>
                                        <!-- <table *ngFor="let data of item.tbtparticipants;let i = index">
                                            <tr>
                                                <td colspan="6" style="font-size: 14px; padding: 3px;"><u>Participant Details:</u>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td style="padding: 3px;">Sr.No</td>
                                                <td style="padding: 3px;text-align: center;">Employee No</td>
                                                <td colspan="2" style="padding: 3px;text-align: center;">Employee Name</td>
                                                <td colspan="2" style="padding: 3px;text-align: center;">Signature</td>
                                            </tr>
                                            <tr >
                                                <td style="padding: 3px;">{{i+1}}</td>
                                                <td style="padding: 3px;text-align: center;">{{data.participantcode}}</td>
                                                <td colspan="2" style="padding: 3px;text-align: center;">{{data.participantname}}</td>
                                                <td colspan="2" style="padding: 3px;text-align: center;"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="font-size: 14px; padding: 3px;"><u>Tool Box Talk Given
                                                        By:-</u>
                                                </td>
                                            </tr>
                                            <tr *ngIf="givenBy.length >= 1">
                                                <td colspan="3" style="padding: 3px; padding-top: 40px;">Name &
                                                    Signature:&nbsp;&nbsp;&nbsp;{{givenBy[0].name}}
                                                </td>
                                                <td colspan="3" style="padding: 3px; padding-top: 40px;">Name &
                                                    Signature:&nbsp;&nbsp;&nbsp;{{givenBy[1].name}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="givenBy.length >= 2">
                                                <td colspan="3" style="padding: 3px; padding-top: 40px;">Designation
                                                    :&nbsp;&nbsp;&nbsp;{{givenBy[0].designation}}</td>
                                                <td colspan="3" style="padding: 3px; padding-top: 40px;">Designation
                                                    :&nbsp;&nbsp;&nbsp;{{givenBy[1].designation}}</td>
                                            </tr>
                                            <tr *ngIf="givenBy.length >=3">
                                                <td colspan="3" style="padding: 3px; padding-top: 40px;">Name & Signature
                                                    :&nbsp;&nbsp;&nbsp;{{givenBy[2].name}}</td>
                                                <td colspan="3" style="padding: 3px; padding-top: 40px;">Name & Signature
                                                    :&nbsp;&nbsp;&nbsp;{{givenBy[3].name}}</td>
                                            </tr>
                                            <tr *ngIf="givenBy.length >=4">
                                                <td colspan="3" style="padding: 3px; padding-top: 40px;">Designation
                                                    :&nbsp;&nbsp;&nbsp;{{givenBy[2].designation}}</td>
                                                <td colspan="3" style="padding: 3px; padding-top: 40px;">Designation
                                                    :&nbsp;&nbsp;&nbsp;{{givenBy[3].designation}}</td>
                                            </tr>
                    
                                        </table> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>


        </div>

    </div>
</div>