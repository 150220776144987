import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as html2pdf from 'html2pdf.js'

@Component({
  selector: 'app-incident-investigation',
  templateUrl: './incident-investigation.component.html',
  styleUrls: ['./incident-investigation.component.scss']
})
export class IncidentInvestigationComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<IncidentInvestigationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    //console.log("tt",this.data)
    this.closePopup();
  }
  ngAfterViewInit() {
    if (this.data.incidentData) {
      this.samplePdf();
    }
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public samplePdf(): void {
    const options = {
      margin: [3, 10, 3, 10],
      name: 'output.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        dpi: 192,
        scale: 4, y: 0, scrollY: 0,
        letterRendering: true,
        useCORS: true
      },
      jsPDF: { unit: 'mm', orientation: 'portrait', format: 'a4' }
    }
    const element: Element = document.getElementById('samplePdf');


    html2pdf()
      .from(element)
      .set(options)
      .save('Incident-Investigation-Report.pdf');
    //this.spinner.hide();

  }


}
