import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentInvestigationComponent } from './incident-investigation.component';



@NgModule({
  declarations: [IncidentInvestigationComponent],
  imports: [
    CommonModule
  ],
  // exports:[
  //   IncidentInvestigationComponent
  // ]
})
export class IncidentInvestigationModule { }
