import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,

    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/private/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'dashboard2',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },

      {
        path: 'jsa',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/private/jsa/jsa.module').then(
            (m) => m.JsaModule
          )
      },
      {
        path: 'equipment',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/private/equipment/equipment.module').then(
            (m) => m.EquipmentModule
          )
      },
      {
        path: 'manage-user',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/private/manage-user/manage-user.module').then(
            (m) => m.ManageUserModule
          )
      },
      {
        path: 'changepassword',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/private/change-password/change-password-routing.module').then(
            (m) => m.ChangePasswordRoutingModule
          )
      },
      {
        path: 'forms',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./forms/forms.module').then((m) => m.FormModule)
      },
      {
        path: 'incident',
        loadChildren: () =>
          import('./pages/private/incident/incident.module').then(
            (m) => m.IncidentModule
          )
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./pages/private/report/report.module').then(
            (m) => m.ReportModule
          )
      },
    ]

  },

  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
