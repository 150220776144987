import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { ChangePassword } from 'src/app/core/service/_ service/change-password.service';
import { pagetitle } from 'src/app/core/_interfaces/page-title';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  
  public sharePageTitle: pagetitle = {
    pagetitle: 'Change Password',
    routerDtls: [{
      routerLink: '/changepassword',
      anchortagCss: 'fas fa-home',
      path: 'Change Password',
      canClick: false
    }]
  }
  public show = false;
  public formData: FormGroup;
  result: any;
  submitted = false;
  error = '';
  hide = true;
  hide1 = true;
  hide3 = true;
  errorMsg = false;
  

  constructor(
    private _fb: FormBuilder,
    private _ChangePassword: ChangePassword,
    private router: Router,
    private authService: AuthService,

  ) {

  }

  ngOnInit(): void {
    this.createForms();
  }
  createForms() {
    this.formData = this._fb.group({
      oldpassword: ["", Validators.required],
      newpassword: ["", Validators.required],
      confirmpassword: ["", Validators.required],
    },)
  }


  // passwordMatchValidator(form: FormGroup) {
  //   const password = form.get('newpassword').value;
  //   const confirmPassword = form.get('confirmpassword').value;

  //   if (password !== confirmPassword) {
  //     form.get('confirmpassword').setErrors({ mismatch: true });
  //   } else {
  //     form.get('confirmpassword').setErrors(null);
  //   }
  // }
  ChangePassword() {
    let param = {
      old_password: this.formData.get('oldpassword').value,
      new_password: this.formData.get('confirmpassword').value
    }
    if (this.formData.get('newpassword').value != this.formData.get('confirmpassword').value) {
      //this.errorMsg = true
      alert(" Confirm Password should be same as new Password ")
      this.formData.reset();
      return
    }
    else {
      this._ChangePassword.changepassword(param).subscribe((res: any) => {
        console.log(res)
        if (res.Status == 200) {
          alert(res.message)
           this.authService.logout().subscribe((res) => {
            if (!res.success) {
              this.router.navigate(['/authentication/signin']);
            }
          });
        }
        else {
          alert(res.message)
        }
      })
    }
  }
  cancel() {
    this.router.navigate(["/dashboard"]);
    this.formData.reset();
  }

}
