//export const API_BASE_URL = "https://stgdilpsmsapi.vvsindia.com/api/"; //local and Stage
export const API_BASE_URL = "https://dilpsmsapi.vvsindia.com/public/api/";//LIVE/PRODUCTION URL

export const API_BASE_URL_REPORT = "https://dilpsmsreport.vvsindia.com/"; //FOR PRODUCTION REPORT ONLY

//For LOGIN
export const API_LOGIN = API_BASE_URL + "login";
// FORGOT PASSWORD
//export const API_FORGOT_PASSWORD + "change-password";
export const API_FORGOT_PASSWORD = API_BASE_URL + "forgot-password";
//CHANGE-PASSWORD
export const API_CHANGE_PASSWORD = API_BASE_URL + "change-password";
//Drop down
export const API_DROPDOWN = API_BASE_URL + "get-incident-master-listing";
//INCIDENT ENTRY//
export const API_ADD_INCIDENT = API_BASE_URL + "add-incident";
export const API_ADD_INCIDENT_POST = API_BASE_URL + "add-incident-post";
//INCIDENT PREPARED bY
export const API_ADD_PREPAREDBY = API_BASE_URL + "get-incident-createdby"
//GET INCIDENT LIST
export const API_GET_INCIDENT_LIST = API_BASE_URL + "get-incident-list";
// INCIDENT REVIEW BY DOCTOR
export const API_INCIDENT_REVIEW_BY_DOCTOR = API_BASE_URL + "review-by-doctor";
//GET INCIDENT INVESTIGATION DETAILS
export const API_GET_INCIDENT_DETAILS = API_BASE_URL + "get-incident-details";
//SUBMIT INCIDENT INVESTIGATION DETAILS
export const API_SUBMIT_INCIDENT_INVESTIGATION = API_BASE_URL + "reported-by-team-lead";
// INCIDENT STATUS HISTORY
export const API_INCIDENT_STATUS_HISTORY = API_BASE_URL + "get-incident-status-history"
//INCIDENT REJECT
export const API_REJECT_INCIDENT = API_BASE_URL + "reject-incident-by-hse";
//INCIDENT TAKE ACTION
export const API_INCIDENT_TAKE_ACTION = API_BASE_URL + "add-incident-stage-review";
//INCIDENT APROVE
export const API_Approve_INCIDENT = API_BASE_URL + "approve-incident-by-hse";
//INCIDENT GET HOD LIST
export const API_GET_HOD_LIST = API_BASE_URL + "get-hod-list";
//INCIDENT REPORTING
export const API_APPROVED_BY_DEPARTMENT_ENGINEER = API_BASE_URL + "approved-by-dept-engineer";
//INVESTIGATION
export const API_INVESTIGATION_TEAM = API_BASE_URL + "investigation-team-create";
//ASSIGN SUBORDINATE
export const API_ASSIGN_SUBORDINATE = API_BASE_URL + "sub-ordinate-assign";
//COMMENT AND UPLOAD PICTURE
export const API_ADD_COMMENT_AND_UPLOAD_PICTURE = API_BASE_URL + "sub-ordinate-review";
//JSA LISTING
export const API_JSA_LIST = API_BASE_URL + "get-jsa-list";
//JSA ACTIVITY LIST
export const API_JSA_ACTIVITY_LIST = API_BASE_URL + "jsa-activity-list";
//JSA MASTERLISTING
export const API_MASTERLISTING = API_BASE_URL + "get-jsa-master-listing";
//PERMIT SUBCATEGORY
export const API_PERMIT_SUBCATEGORY = API_BASE_URL + "get-jsa-permit-items";
//ADD PERMIT DETAILS
export const API_ADD_PERMIT_DETAILS = API_BASE_URL + "add-jsa-permit-details";
//DELETE PERMIT DETAILS
export const API_DELETE_PERMIT_DETAILS = API_BASE_URL + "delete-jsa-permit";
//GET PERMIT DETAILS
export const API_GET_PERMIT_DETAILS = API_BASE_URL + "get-jsa-permit-item-details";
//GET PERMIT Table
export const API_GET_PERMIT_TABLE = API_BASE_URL + "get-jsa-permit-type-list";
// JSA SUB TYPE OF PARAMETER
export const API_SUB_PERMIT_TYPE = API_BASE_URL + "get-permit-sub-category";
//GET-PLANT-LIST
export const API_GET_PLANT_LIST = API_BASE_URL + "get-plant-list";
//GET-SUBPLANT-LIST
export const API_GET_SUBPLANT_LIST = API_BASE_URL + "get-subplant-list";
//GET-EQUIPMENT-MASTER
export const API_GET_EQUIPMENT_MASTER = API_BASE_URL + "get-equipment-master";
//GET KKS CODE
export const API_GET_KKS = API_BASE_URL + "get-equipment-master-details"
//ADD JSA HEADER
export const API_ADD_JSA_HEADER = API_BASE_URL + "add-jsa-header";
//GET JSA DETAILS
export const API_GET_JSA_DETAILS = API_BASE_URL + "get-jsa-details";
//GET JSA PRINT VIEW

export const API_JSA_PRINT = API_BASE_URL_REPORT + "jsa-report/pdf";
//JSA-STATUS-HISTORY
export const API_GET_JSA_STATUS_HISTORY = API_BASE_URL + "get-jsa-status-history"
//ADD_JSA_ACTIVITY
export const API_ADD_JSA_ACTIVITY = API_BASE_URL + "add-jsa-activity"
//JSA_LIST_APPROVED
export const API_ADD_JSA_LIST_APPROVED = API_BASE_URL + "jsa-approve"
//EDIT-JSA-ACTIVITY
export const API_EDIT_JSA_ACTIVITY = API_BASE_URL + "edit-jsa-activity"
//DELETE-JSA-ACTIVITY
export const API_DELETE_JSA_ACTIVITY = API_BASE_URL + "delete-jsa-activity"

//JSA-LIST-REVISED
export const API_JSALIST_REVISED = API_BASE_URL + "revise-jsa"
//JSA-TBT DETAILS
export const API_JSA_TBT_DETAILS = API_BASE_URL + "jsa-tbt-details"
export const API_JSA_TBT_REPORT = API_BASE_URL + "jsa-tbt-report"
//ADD-JSA-TBT
export const API_JSA_TBT_ENTRY = API_BASE_URL + "add-jsa-tbt"
//EMPLOYEE- DETAILS
export const API_EMPLOYEE_DETAILS = API_BASE_URL + "get-employee-details"
//ADD-TBT-GIVEN-BY
export const ADD_TBT_GIVEN_BY = API_BASE_URL + "add-jsa-tbt-given-by"
//EDIT-JSA-TBT-DETAILS
export const EDIT_JSA_TBT_DETAILS = API_BASE_URL + "edit-jsa-tbt-details"
//EDIT-JSA-TBT-GIVEN-BY
export const EDIT_JSA_TBT_GIVEN_BY = API_BASE_URL + "get-jsa-tbt-givenby-details"
//JSA-FINAL_SUBMIT
export const API_JSA_FINAL_SUBMIT = API_BASE_URL + "jsa-final-submit"
//JSA-REJECT
export const API_REJECT_JSA = API_BASE_URL + "jsa-reject"
//EQUIPMENT MASTER LISTING
export const API_EQUIPMENT_MASTER_LISTING = API_BASE_URL + "get-equipment-master-listing"
//DELETE JSA
export const API_DELETE_JSA = API_BASE_URL + "delete-jsa-details"

//EQUIPMENT LIST
export const API_EQUIPMENT_LIST = API_BASE_URL + "get-equipment-list"
export const API_POSR_EQUIPMENT_LIST = API_BASE_URL + "get-post-equipment-list"
//EQUIPMENT DEPLOYMENT
export const API_EQUIPMENT_DEPLOYMENT = API_BASE_URL + "add-equipment"
export const API_POST_EQUIPMENT_DEPLOYMENT = API_BASE_URL + "equipment-deployment"
export const API_GET_EQUIPMENT_ITEM = API_BASE_URL + "get-equipment-item"
export const API_GET_EQUIPMENT_DETAILS = API_BASE_URL + "get-equipment-details"
//API_DELETE_EQUIPMENT_DETAILS
export const API_DELETE_EQUIPMENT_DETAILS = API_BASE_URL + "delete-equipment-details"
export const API_APPROVE_REJECT_EQUIPMENT_DETAILS = API_BASE_URL + "review-equipment"
//API_GET_DASHBOARD_DETAILS
export const API_GET_DASHBOARD_TOTAL_RECORDS = API_BASE_URL + "dashboard-total-record"
export const API_GET_DASHBOARD_INCIDENT_RECORDS = API_BASE_URL + "dashboard-incident-total-record"
export const API_GET_DASHBOARD_INCIDENT_MONTHLY_RECORD = API_BASE_URL + "incident-monthly-record"
///API MANAEG USER
export const API_MANAGE_USER_LIST = API_BASE_URL + "fetch-user-management-list"

export const API_ROLE_LIST = API_BASE_URL + "get-user-master-listing"
export const API_ADD_USER = API_BASE_URL + "add-user"
export const API_GET_USER = API_BASE_URL + "get-user-details"

