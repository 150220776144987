import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
export class Helper {

  constructor() { }

  dateFormatForIncident(dateValue = '', format = 'yyyy-MM-dd HH:mm:ss') {
    if (dateValue === '') { return dateValue; }
    const datePipe = new DatePipe('en-US');
    dateValue = datePipe.transform(dateValue, format);
    return dateValue;
  }
  dateFormatForIncidentList(dateValue = '', format = 'yyyy-MM-dd') {
    if (dateValue === '') { return dateValue; }
    const datePipe = new DatePipe('en-US');
    dateValue = datePipe.transform(dateValue, format);
    return dateValue;
  }
  enableFormFields(formControl: FormGroup, Info: any = null): FormGroup {
    let InfoKeys: String[] = [];
    if (Info != null) InfoKeys = Object.keys(Info);
    for (let fieldName of Object.keys(formControl.controls)) {
      if (!InfoKeys?.length) formControl.get(fieldName)?.enable();
      else if (InfoKeys.indexOf(fieldName) !== -1 && Info[fieldName].editable) formControl.get(fieldName)?.enable();
    }
    return formControl;
  }
  setDetailsInfoForm(Info: any = null, formControl: FormGroup): FormGroup {
    let fieldValue: any = "";
    //console.log(Object.keys(Info))
    for (let fieldName of Object.keys(Info)) {
      //console.log(fieldName)
      if (formControl.get(fieldName) == null) continue;
      fieldValue = Info[fieldName].value;
      // console.log(fieldValue)
      formControl?.get(fieldName)?.setValue(fieldValue);
      if (Info[fieldName].editable) {
        formControl.get(fieldName).enable();
      } else {
        formControl.get(fieldName).disable();
      }
    }
    return formControl;
  }
  SubmitData(formControl: FormGroup) {
    let Data: any = [];
    let fieldValue: any = null;
    for (let fieldName of Object.keys(formControl.controls)) {
      if (formControl.get(fieldName) == null) continue;
      fieldValue = formControl?.get(fieldName)?.value;
      Data[fieldName] = fieldValue;
    }

    return Object.assign({}, Data);
  }





  disableFormFields(formControl: FormGroup): FormGroup {
    for (let fieldName of Object.keys(formControl.controls)) {
      formControl.get(fieldName)?.disable();
    }
    return formControl;
  }
}
