<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="dashboard/main">
        <img src="assets/images/logo-signin.jpg" alt="" />
        <!-- <span class="logo-name">DIL -PSMS</span> -->
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <h4 class="page-title">{{sharePageTitle.pagetitle}}</h4>
      <span class="ag-breadcrumb pt-10">
        <!-- Dashboard -->
        <ul class="breadcrumb breadcrumb-style ">
          <!-- <li class="breadcrumb-item">
            <h4 class="page-title">{{sharePageTitle.pagetitle}}</h4>
          </li> -->
          <!-- <div> -->
          <li class="breadcrumb-item bcrumb-1" *ngIf="PSMSUser">
            <span *ngFor="let row of sharePageTitle.routerDtls">
              <a href="" target="_blank" (click)="onClickLink(row,$event)">
                <i [ngClass]="row.anchortagCss"></i> {{row.path}}</a>
            </span>
          </li>
          <li class="breadcrumb-item active" *ngIf="PSMSUser">{{sharePageTitle.pagetitle}}</li>
          <!-- </div> -->
        </ul>
      </span>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <!-- <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li> -->
        <!-- #END# Full Screen Button -->
        <!-- <li class="nav-item" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li> -->
        <!-- #START# Notifications-->
        <!-- <li class="nav-item" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon>notifications_none</mat-icon>
            <span class="label-count bg-orange"></span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href="#" *ngFor="let notification of notifications" onClick="return false;">
                    <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                      <span class="menu-desc">{{notification.message}}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1" href="#" onClick="return false;">Read
              All Notifications</a>
          </div>
        </li> -->
        <!-- #END# Notifications-->
        <li class="nav-item user_profile" ngbDropdown>
          <!-- <div class="d-flex align-items-center">
            <span class="m-r-20 ag-user-name">{{PSMSUser?.firstname}} {{PSMSUser?.lastname}}</span>
            
          </div> -->
          <a href="#" onClick="return false;" ngbDropdownToggle role="button"
            class="nav-notification-icons pt-0 d-flex align-items-center">
            <mat-icon id="settingBtn">settings</mat-icon>
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;" (click)="changepassword()">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Change Password
                      </a>
                    </li>
                    <!-- <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                      </a>
                    </li> -->
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
        <!-- <li class="float-end">
          <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons">
            <mat-icon id="settingBtn">settings</mat-icon>
          </button>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
